import React from 'react'
import ReactDOMServer from 'react-dom/server';
import QRCode from 'react-qr-code';
import jwtDecode from 'jwt-decode'

import {Font} from '@react-pdf/renderer';
import copyToCanvas from './svgToImg'
import OpenSans from "../../fonts/OpenSans-Regular.ttf"
import OpenSansBold from "../../fonts/OpenSans-Bold.ttf"
import Hurricane from '../../fonts/Hurricane-Regular.ttf'
import Mono from '../../fonts/UbuntuMono-Regular.ttf'

import Diploma from './templates/diploma'

Font.register({
    family: 'OpenSans',
      format: "truetype",
      src: OpenSans
  });

Font.register( {
  family: "OpenSansBold",
  //format: "truetype",
  src: OpenSansBold
})

Font.register( {
    family: "Hurricane",
    //format: "truetype",
    src: Hurricane
  })
  
Font.register( {
  family: "Mono",
  // format: "truetype",
  src: Mono
})

Font.registerHyphenationCallback(word => (
  [word]
));


export default function PdfPaper (props) {
/*
   const item = {
      code: props.item.code,
      badgeType: {
        title: "Diploma di Specializzazione per le attività di Sostegno didattico agli alunni con disabilità nella Scuola dell’Infanzia",
        
        extra: {
          preTitle: "ha conseguito nell'AA 2020/2021 il",
          eventDate : "12/3/2021", 
          eventPlace: "Messina",
          eventSigner: "Prof. Aldo Epasto",
          eventSignerDescription: "Il Direttore dei percorsi",
          footer: "Attestazione ai sensi del D.M. 30 Settembre 2011",
          header: "Attestato di Specializzazione",
          showBadgeIdentifier: true, 
          badgeIdentifier: "dip_tfa_2021_678901",
          showSecondSigner: true, 
          secondSigner: "Dott. Pietro Nuccio",
          secondSignerDescription: "Direzione Servizi Didattici e Alta Formazione",
          showQRCode: true
        }
      } 

   }
*/
    const { item } = props

    const {code} = item

    const decoded = jwtDecode(code) 

    const siteLink = "https://badge.unime.it/#verify?code=" + code

    const [imgSvg, setImg] = React.useState(null)

    React.useEffect ( ()=> {
        const svg = ReactDOMServer.renderToStaticMarkup(<QRCode value={siteLink} size={200} level="M" />)
        const doc = new DOMParser().parseFromString(svg, "image/svg+xml");
        copyToCanvas(doc).then(data => {
            setImg(data)
        })

    },  [siteLink])

   if(item.code)
  {
    switch(item.template){
      case "template1": 
      case "template2":
        default:
          return <Diploma item={item} decoded={decoded} qrCode={imgSvg}  />
    }
    
  }
   

return null
}