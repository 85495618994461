export default function validUserRole (roleList, userRoles){
    // roleList è un Array di ruoli validi
    // role contiente l'attributo da validare ed è un array come da #29
    try{
        for (const role of userRoles)
            for ( const item of roleList) {
                /*
                const regEx = "^" + item.toLowerCase() + "$"
                const regularEx = new RegExp(regEx,'i')
                const validRole = regularEx.test(role)
                */
               
                const validRole = item.toLowerCase().indexOf(role.toLowerCase()) > -1

                if(validRole) {
                    //console.log('valid', validRole)
                    return true
                }
                    
                
                //console.log(regularEx, user.role, validRole )
            }
    } catch(err) {
        console.log(err)
    }
    console.log('no valid role')
    return false
}

export function isAdmin (userRoles){
    //console.log(userRoles)
    try{
        for (const role of userRoles){
            const regularEx = new RegExp('^admin','i') // TODO: migliorare la funzione con "INIZIA CON" startsWith "^"
            const validRole = regularEx.test(role)

            if(validRole)  
                return true
        }
    } catch(err) {
        console.log(err)
    }
    return false
}
