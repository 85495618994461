/**
 * Attenzione, ogni componente aggiunto, deve essere anche incluso in App.js
 */

/**
 * successivamente dobbiamo riuscire a fare il build con gitlab-runner e variabili d'ambiente l'ideale sarebbe creare un immagine docker
 * const basePath = (process.env.NODE_ENV=='production')? process.env.REACT_BASE_PATH_PROD:REACT_BASE_PATH_TEST
 *
 * per il momento hard-coded e il basepath è scelto runtime
*/
import React from 'react'
let basePath = window.location.hostname === 'badge.unime.it'?'https://badge-backend.unime.it':'https://badge-backend.unime.it' //'https://badge-backend-test.devsciam.azure.unime.it'

const debugLocal = localStorage.getItem('debug') === 'true'
const apiPath = basePath

//const ADMIN = 'Admin'
const AUTENTICATI = ['Authenticated'] // Tutti gli utenti autenticati

const config = {
  siteLogo: window.location.origin + '/assets/logo_unime.png',
  siteTitle: <> </>,
  version: "Versione 1.1.3",
  maintenance: false, //Maintenance Mode
  mobileHeadTitle : <>Unime <i>Badge</i></>,
  navStyle: {
     backgroundColor: "#0C6DAE" ,
     color: "white"  
  },
  footerStyle : {
    backgroundColor: "#0C6DAE" ,
     color: "white",
     padding: 12
  },
  siteLabel: " ",
  debug: debugLocal || false, //!PRODUCTION, 
  defaultLanguage: 'it-IT',
  languages: [
    { code: "it-IT",  text: "Italiano", img: "it.png"},
    { code: "en-EN", text: "English", img: "gb.png"}
  ],
  dashboardUrl: apiPath * "/dashboard",
  flagBaseUrl: "/assets/flags/",
  helpButton: {enabled: false, url: "https://sway.office.com/fYYq8IBABdoPEw7h?ref=Link", title:"Guida all'uso"},
  siteImg: "/assets/landing.png",
  altImg: "Login to UniME Badge",
  // Autenticazione
  auth : {
    login : basePath + '/connect/simplesaml',
    logout : 'https://minosse.unime.it/simplesaml/saml2/idp/SingleLogoutService.php?ReturnTo=https://badge.unime.it',
    getStrapiToken : basePath + '/auth/simplesaml/callback'
  },
  // Api per prelevare i dati degli utenti.
  users : {
      api: apiPath + '/users/me'
    },
  // gestione dei componenti che saranno usati nel programma
  app : [
    {
      nameSpace: 'core',
      role: AUTENTICATI,
      hidden: { menu : true, route: true, component: false},
      api: {
        getMessages: basePath + "/messages",
        getConfigs: basePath + "/configs"
      }
    },
    {
      nameSpace: 'my',
      role: AUTENTICATI,
      default: AUTENTICATI,
      menuLabel: 'ATM',
      pageTitle: "Atm",
      annotations: "",
      path: '/badge',
      image: window.location.origin + '/assets/utente.jpg',
      Component: 'My',
      attributes: {},
      badgeLogos: {
        'atm2021' : {
          enabled: true,
          url: window.location.origin + '/assets/atm2021_logo.png',
          style:{maxWidth: "95%"}
        },
        'atm2022' : {
          enabled: true,
          url: window.location.origin + '/assets/atm2021_logo.png',
          style:{maxWidth: "95%"}
        },
        'atm2023' : {
          enabled: true,
          url: window.location.origin + '/assets/atm2021_logo.png',
          style:{maxWidth: "95%"}
        }
      },
      api : {
          getBadgeList : apiPath + '/badge-types',
          getQr : apiPath + '/badges',
          postQr: apiPath + '/badges',
      }
    },
    {
      nameSpace: 'certificates',
      role: AUTENTICATI,
    //  hidden: true,
      menuLabel: 'Attestati',
      pageTitle: "Attestati",
      annotations: "",
      path: '/attestati',
      image: window.location.origin + '/assets/utente.jpg',
      Component: 'Certificates',
      attributes: {},
      api : {
          getList : apiPath + '/badge-types?category=attestati',
          getById : apiPath + '/badges?tipo=statico&badgeType=',
      }
    },
  ],
  // Non cancellare... lasciare vuoto se non c'è parte amministrativa
  appAdmin : [

  ]
}

export default config
