import moment from 'moment'
import {PDFViewer,  Page, Text, View, Document, Image, pdf , StyleSheet} from '@react-pdf/renderer';
import diploma from './styles'

const pdfStyles = StyleSheet.create(diploma);


const Diploma = ({item, decoded, qrCode}) => {
    const {title} = item.badgeType
    const {eventDate, eventPlace, eventSigner, 
        eventSignerDescription, footer, preTitle, 
        header,  showBadgeIdentifier, badgeIdentifier,
        showSecondSigner, secondSigner, secondSignerDescription,
        showQRCode, //ShowVerifyLink, verifyLink, postTitle,
    } = item.badgeType.extra
  
    return <PDFViewer style={{width: "100%", height:"50vh"}}>
    <Document>
  <Page size="A4" orientation='landscape' object-fit="fill" style={pdfStyles.page}>
    <View style={pdfStyles.pageBackground}>
      <Image src={`/assets/eventi.png`} width="100%" style={pdfStyles.pageBackground} />  
        <View>
            <Text style={pdfStyles.header}>{header}</Text>
            <View style={pdfStyles.utente}>
                <Text style={pdfStyles.nome}>
                  {decoded.firstName} {decoded.lastName} &nbsp;
                  <Text style={pdfStyles.matricola}>
                      [{decoded.matricola}]
                  </Text>
                </Text>
                {decoded.birthDate && <Text style={pdf.nascita}>Nat{decoded.sex === "M" ? "o ": "a "} il {decoded.birthDate}</Text>}
            </View>      
            <Text style={pdfStyles.title_text}>{preTitle}</Text>
            <Text style={pdfStyles.title}>{title}</Text>
            <Text style={pdfStyles.data}>{eventPlace},&nbsp;
                <Text style={{fontWeight: "bold"}}>{moment(eventDate, "YYYY-MM-DD").format("DD/MM/YYYY")}</Text>
            </Text>
            <Text style={pdfStyles.organizzatore}>  
                <Text>{eventSignerDescription}{"\n"}</Text>
                <Text style={pdfStyles.organizzatore_sign}>{eventSigner}{"\n\n"}</Text>
                {showSecondSigner && <Text style={{fontWeight: "bold"}}>
                  <Text>{secondSignerDescription}{"\n"}</Text>
                  <Text style={pdfStyles.organizzatore_sign}>{secondSigner }</Text>
                </Text>}
            </Text>
            <View style={pdfStyles.code} >
                 {showQRCode && qrCode ? <Image src={qrCode} width="5.5cm" style={{marginLeft: 14, marginTop: 14, width: "5.5cm", height: "5.5cm"}}/> : null 
                 }
                 {showBadgeIdentifier && <Text style={{paddingTop: "-10pt"}}>{badgeIdentifier}</Text>} 
            </View> 
            <Text style={pdfStyles.note} >
               {footer}  
            </Text> 
        </View>
    </View>
  </Page>
  </Document>
  </PDFViewer>
  
  }

  export default Diploma