import React from 'react'

const defaultContext = {
    message: [], 
    setMessage: () => {}, 
    language: "", 
    setLanguage: () => {},
    token: "",
    isAuthenticated: false,
    user: {},
    role: [],
    configs: {}
}
const StoreContext = React.createContext(defaultContext)

export {defaultContext}
export default StoreContext
