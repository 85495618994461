import React from 'react'
import {Row, Col, Input, Alert, Button} from 'reactstrap'
import {saveData} from '../../utils'

export default function DebugContent (){
    const [token, setToken] = React.useState({})

    function saveIt(token){
        if(token !== ''){
           saveData(token)
           window.location.reload();
        } else {
            alert('Inserisci un token')
        }
    }

    return <Alert color="success">
        <Row>
            <Col>
                <Input onChange={(e)=>setToken(e.target.value)} placeholder="Token per login" />
            </Col>
            <Col>
                <Button color="primary" onClick={()=>{saveIt(token)}}>Registra Token</Button>
            </Col>
        </Row>
    </Alert> 
}