import React from 'react'


 const Informativa = () => {
    return <>
    <h2>INFORMAZIONE AI SOGGETTI INTERESSATI UTILIZZATORI DELL’APP <i>BADGE UNIME</i></h2>
    <h3>Contesto del trattamento</h3>
    <p>
        Il Titolare del trattamento dei dati personali raccolti nel contesto di utilizzo dell’APP <i>BADGE UNIME</i>. è l'Università degli Studi di Messina con sede legale in Piazza Pugliatti 1 98122 Messina contattabile ai seguenti recapiti: rettorato@unime.it; protocollo@pec.unime.it; http://www,unime.it.
        <br />
        La finalità del trattamento è di carattere istituzionale e si inserisce nell’ambito delle iniziative, ricomprese nella c.d. terza missione dell’Ateneo, volte a promuovere ed attivare un servizio di mobilità sostenibile sulla base di un progetto convenzionato con l’Azienda A.T.M. S.p.A. di Messina, i cui obiettivi ricomprendono anche il rilascio di abbonamenti per il trasporto pubblico locale a tariffa agevolata.
        <br />
        Il servizio, la cui adesione è volontaria, è rivolto agli studenti e al personale dipendente dell’Ateneo. I dati personali trattati, già tenuti presso le banche dati dell’Ateneo, sono quelli necessari e strumentali alla strutturazione e alla gestione dell’abbonamento: Nome, Cognome, matricola; foto personale; sarà inoltre generato un  numero distintivo dell’abbonamento nel formato minimizzato QR Code. 
        <br />
        La base giuridica del trattamento è quella dell'esecuzione di un compito di interesse pubblico e dell’esecuzione di un contratto di cui l’interessato è parte (quale fruitore del servizio), rispettivamente di cui all'art. 6 par. 1 lett. e) all’art. 6 par. 1 lett. b) del Regolamento generale UE sulla protezione dei dati n. 679/2016.
    </p>
    <h3>Operazioni di trattamento</h3>
    <p>
        Limitatamente a quanto necessario rispetto alla finalità, le operazioni di trattamento - ivi incluse la comunicazione a terzi e la conservazione delle informazioni personali - saranno eseguite prevalentemente in modalità informatizzata in ossequio dei principi di qualità dei dati (minimizzazione, esattezza, adeguatezza; liceità, correttezza e trasparenza) e dei requisiti di sicurezza, nonché nel pieno rispetto delle norme vigenti,  dei diritti fondamentali della persona e della sua dignità. 
        <br />
        L’Azienda <i>A.T.M. S.p.A.</i> di Messina è soggetto destinatario e, con riferimento ai successivi possibili riutilizzi dei dati personali, agisce come autonomo Titolare del trattamento nei limiti dei propri compiti e delle competenti attività di gestione e controllo. Inoltre, per specifiche finalità prescritte da norme di legge l’Ateneo potrà, nel rispetto del principio di minimizzazione, comunicare i dati personali ad altri soggetti terzi che agiscono in qualità di Titolari autonomi del trattamento. 
        <br />
        Alla scadenza, l’abbonamento sarà oggetto di  de-identificazione per eventuali riutilizzi di carattere statistico.
        <br/>
        In dati personali non saranno trasferiti a paesi terzi o organizzazioni internazionali ne riutilizzati per operazioni di profilazione.
    </p>
    <h3>Diritti dei soggetti interessati</h3>
    <p>
        I candidati partecipanti possono azionare i diritti di cui agli artt. 15 e ss. del Regolamento generale UE sulla protezione dei dati n. 679/2016; in particolare, possono accedere ai propri dati personali, chiederne la rettifica, la cancellazione, la limitazione del trattamento, nonché di opporsi al loro trattamento, rivolgendo le richieste all'Università degli Studi di Messina utilizzando i citati recapiti.  
        <br />
        Inoltre, qualora i partecipanti ritengano che il trattamento comporti una violazione dei propri diritti o sia eseguito in violazione del citato Regolamento Europeo possono proporre reclamo all'Autorità Garante per la Protezione dei Dati Personali (www.garanteprivacy.it), come previsto dall'art.77 del Regolamento Europeo, o promuovere azioni presso le competenti Autorità giurisdizionali (art. 79 del Regolamento Europeo).
        <br />
        Ulteriori informazioni sono disponibili al seguente link della sezione privacy di Ateneo: <a rel="noopener noreferrer" href="https://www.unime.it/it/ateneo/privacy" target="_blank">https://www.unime.it/it/ateneo/privacy</a>
    </p>
</>
}

export default Informativa