
const copyToCanvas = ( target, scale = 1, format = "png", quality = 1) => {
    const svg = target.querySelector('svg');
    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement('canvas');
    const svgSize = 2000;
    
    
    canvas.width = 225 * scale;
    canvas.height = 225 * scale;
    canvas.style.width = svgSize;
    canvas.style.height = svgSize;
  
    let ctxt = canvas.getContext('2d');
    ctxt.scale(scale, scale);
  
    const img = document.createElement('img');
    img.setAttribute('src', 'data:image/svg+xml;base64,' + btoa(unescape(encodeURIComponent(svgData))));
    return new Promise(resolve => {
        img.onload = () => {
    
        ctxt.drawImage(img, 0, 0);
        
        const file = canvas.toDataURL(`image/${format}`, (format = 'png'), quality);
        
        resolve(file);
        };
    });
    
  };

  export default copyToCanvas