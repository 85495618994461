import config from '../../config'
import axios from 'axios'

const {app} = config;

const {api} = app.filter( (item) => (item.nameSpace === "my"))[0]

export default function getBadgeList(token, config) {
    const axiosConfig = {...config,
        headers: ({
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
         }),
         timeout: 2000
    }
    return axios.get(api.getBadgeList, axiosConfig)
}

export function getQr(token, staticQr, postData) {
    const axiosConfig = {
        headers: ({
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
         }),
         timeout: 1000
    }
    const url = staticQr ? "?tipo=statico&" : "?tipo=dinamico&"
    //console.log(postData)
    return axios.post(api.getQr + url + postData, {}, axiosConfig)
}
