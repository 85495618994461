import React from "react";
import { Card, CardBody, CardTitle, CardText, CardImg, Row, Col } from 'reactstrap'
import axios from 'axios'
import config from '../../config'

const styles = {
  value : { textShadow: "2px 2px 5px #000"},
  card: {marginBottom: 20},
  image: {border: 'none', paddingBottom: 50},
  
}

 const InfoCard = ({title, value, color}) => <Card style={styles.card} color={color} inverse>
 <CardBody>
   <CardTitle tag="h5" >
     <span className="text-white ">{title}</span>
   </CardTitle>
   <CardText tag="h1">
     <p style={styles.value}><strong>{value}</strong></p>
   </CardText>
 </CardBody>
</Card>

 const Dashboard =  (props) => {
    const url = config.dashboardUrl;
    const [state, setState] = React.useState({})

    React.useEffect (
        () => {
            axios.get(url)
            .then(({data}) => {
                setState(data)
            })  
            .catch((err) => {
                console.log(err)
            })
        }
    , [url])
/*
    if(!state)
        return null;
*/
    const {studenti = 3703, impiegati = 3, lastUpdate = "02/12/2021" } = state

    return <div className="container">
        <Row className="justify-content-sm-center">
            <Col sm="5">
                <Card style={styles.image}>
                    <CardImg src={config.siteImg} alt={config.altImg} width="50%"/>
                </Card>
            </Col>
        </Row>
        <Row className="justify-content-sm-center">
            <Col sm="3">
                <h3 className="text-muted">Abbonati</h3>
                <InfoCard color="success" title={"Studenti"} value={studenti} />
                <InfoCard color="secondary" title={"Impiegati"} value={impiegati} />
            </Col>
            {/**
             * <Col sm="3">
                <h3 className="text-muted">Qr-Code generati</h3>
                <InfoCard color="primary" title={"Statici"} value={statici} />
                <InfoCard color="info" title={"Dinamici"} value={dinamici} />
            </Col>
             */}
            
        </Row>
        <Row>
            <Col className="text-center text-muted">
                <p>Ultimo Aggiornamento dati {lastUpdate}</p>
            </Col>
        </Row>
    </div>
 }
 
 
 
 
 export default Dashboard