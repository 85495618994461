

const diploma = {
    page: {
      
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1
    },
    pageBackground: {
        position: 'absolute',
        minWidth: '100%',
        minHeight: '100%',
        display: 'block',
        height: '100%',
        width: '100%',
      },
      code: { 
          position: "absolute", 
          left: "1cm", 
          top: "14cm", 
          width: "6cm", 
          height: "6cm", 
          fontSize: "7pt",  
          fontFamily: "Mono",
          textAlign: "center"
        },
      matricola: { fontSize: "18pt", color: "#555", display: "block", margingBottom: "1pt", fontFamily: 'OpenSans'},
      utente: { 
          position: "absolute",  
          width:"26cm",  
          left: "2cm", 
          top: "6cm", 
          textAlign: "center"
        }, 
      nome: {fontSize: '24pt', 
          fontFamily: 'OpenSansBold'},
      nascita: {
          fontSize: '16pt', 
          fontFamily: 'OpenSans'
      },
      header: {fontFamily: 'OpenSansBold', position: "absolute", width:"24cm", left: "4.6cm", top: "1.5cm", fontWeight: "bold", fontSize: '36pt', color: "#0478c8", textAlign: "center"},
      title: { fontFamily: 'OpenSansBold', position: "absolute", width:"24cm", left: "2cm", top: "9.2cm", fontWeight: "bold", fontSize: '22pt', color: "#0478c8", textAlign: "center"},
      title_text: { fontFamily: 'OpenSans', position: "absolute", width:"24cm", left: "2cm", top: "8.2cm", fontSize: '18pt',  textAlign: "center"},
      data: { position: "absolute", left: "1.5cm", top: "13cm", fontSize: '13pt', width: "8cm",},
      organizzatore: { position: "absolute", width:"12cm", left: "18cm", top: "13cm", lineHeight: '1.3pt', fontSize: '14pt',  textAlign: "center"},
      organizzatore_sign: { fontFamily: "Hurricane", fontSize: "20pt"},
      description: {textAlign: 'left', fontSize:"10pt", fontWeight: "bold", paddingTop: 5, display: "block", width:"4cm", color:"#222"},
      note: { position: "absolute",  left: "2cm", top: "19.7cm", width:"24cm", fontSize: '10pt', textAlign: "center"},
      codeSize: 125,
  }

  export default diploma