import React from 'react';
import { Row} from 'reactstrap';
import CardWidgets from './cardWidgets'
import HomePublic from './homePublic'
import HomeProtected from './homeProtected'
import StoreContext from '../core'

export default function HomePage () {
  const {isAuthenticated, user, role} = React.useContext(StoreContext) 

    return (
      <>
      <Row>
        {isAuthenticated
        ? 
        <HomeProtected user={user} role={role}/> 
        : <HomePublic />}
      </Row>
      <br/>
      {isAuthenticated && role ?
        <>
          <CardWidgets role={role}/>
        </>
      :
        null
      }
      <br/>
    </>
    );
  }