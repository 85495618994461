import jwtDecode from 'jwt-decode'
import moment from 'moment'
// eslint-disable-next-line
import momentTimezone from 'moment-timezone'


export function formatDate (date){
    if(!date) 
    return null
    // momentTimezone
    return moment(date)
    .tz('Europe/Rome')
    .format('DD/MM/YYYY HH:mm')
  }

/**
 * TOKEN TOOLS
 * @param {*} token 
 * @param {*} user 
 */
export function saveData(token) {
    console.log('saving data.')
    localStorage.setItem('token', token)
    return true
}


export function clearData() {
    localStorage.clear('token')
    localStorage.clear('user')
    return Promise.resolve()
}

export function tokenScaduto (token) {
    try{
        const tok = jwtDecode(token)
        let exp = moment.unix(tok.exp)
        let now = moment()

    return moment(exp).isSameOrBefore(now)

    }catch( err) {
        console.log(err)
        return true
    }
}

/**
 * getToken prende il token dal localstorage e lo controlla con la data corrente del browser.
 */
export default function getToken () {
    console.log('getLocalToken')
    let error = ""

    try{
        const token = localStorage.getItem('token')
        console.log(token)
        //console.log(tok)
        //const token = JSON.parse(tok)
        //console.log(token)
        const expirated = tokenScaduto(token)
        console.log(expirated)
        if(expirated)
           return Promise.reject({message: "Token scaduto.", token})
        
        if(token){
            console.log(token)
            return Promise.resolve({token:token})
        }
    }catch(err){
        error = err.message || "Accesso non eseguito o sessione scaduta."
    }
       
    return Promise.reject({message: error || "Token non esistente."})
}

export function getLanguage () {
    try{
       return Promise.resolve(localStorage.getItem('language'))
    }catch(err){
        return Promise.reject(null)
    }
}