import config from '../../config'
import axios from 'axios'

const {app} = config;

const {api} = app.filter( (item) => (item.nameSpace === "certificates"))[0]

export default function getList(token) {
    const axiosConfig = {
        headers: ({
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
         }),
         timeout: 2000
    }
    return axios.get(api.getList, axiosConfig)
}

export function getCertificate(token, id, postData) {
    const axiosConfig = {
        headers: ({
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
         }),
         timeout: 1000
    }
    
    //console.log(postData)
    return axios.post(api.getById + id, {} , axiosConfig)
}
