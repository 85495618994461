import React, {useState, useContext} from 'react'
import { Button, Modal, ModalHeader, ModalBody, ListGroup, ListGroupItem, Collapse, ModalFooter, Container, Col, Row} from 'reactstrap';
import QrCard from './qrCard'
import QrDynamic from './qrDynamic'
import getBadgeList from './api'
import StoreContext from '../core/storeContext'
import Informativa from '../core/informativa'

export default function My () {
    const [modal, setModal] = useState(false);
    const [staticQr, setStaticQr] = useState(false);
    const {user, token} = useContext(StoreContext)
    const [badgeList, setBadgeList ] = useState([])
    const [thisItem, setthisItem] = useState("")
    const [openInform, setOpenInform] = useState(false)

    const toggleInform = ()=>setOpenInform(!openInform)
    const toggle = () => setModal(!modal);

    function openQr(id) {
        setStaticQr(false)
        setthisItem(id)
        setModal(true)
    }

    function openBadge(id){
        setStaticQr(true)
        setthisItem(id)
        setModal(true)
    }

    React.useEffect ( ()=>{

      const fetchData = async  () => {
        const res = await getBadgeList(token)
        setBadgeList(res.data)
        return res
      }

      fetchData()
      
      
    
    }, [token])
  

    return <Container fluid>
      {/** Ciclo di tutti i badge disponibili... */}
     
      <ListGroup style={{paddingTop: 20}}>
          {badgeList.map( (item,idx) => 
                      item.enabled && <ListGroupItem key={idx} >
                        <Row className="d-flex align-items-center">
                          <Col className="align-items-center p-2" onClick={()=>openQr(item.id)} md={10} sm={12}> 
                            <Button color="primary" size="lg" style={{width: '95%'}}>{` `}<i className="fa fa-qrcode"></i>{` `} {item.description} 
                           </Button>
                          </Col>
                          {item.codeTypes.indexOf("statico") > -1 ?
                          <Col className="align-items-center p-2 d-none d-sm-block" md={1}>
                            <Button color="primary" outline size="sm" onClick={()=>openBadge(item.id)}><i className="fa fa-print"></i><br />Stampa</Button>
                          </Col>
                          : null
                          }
                        </Row>  
                      </ListGroupItem>  
                  )}
      </ListGroup>
      <div style={{padding: 50, paddingLeft: 10}}>
        <Button  color="link" onClick={()=>toggleInform()}><b>Informativa</b> <i className={`fa fa-caret-${openInform ? "up" : "down"}`}></i></Button>
      <Collapse isOpen={openInform}>
      <div style={{height: 400, overflowY: "scroll", fontSize: '0.8em', color: "grey"}}>
        <Informativa />
      </div>
      </Collapse>
      </div>
        <Modal isOpen={modal} toggle={toggle} size="lg">
            <ModalHeader toggle={toggle}>Badge {user.cognome} {user.nome}</ModalHeader>
            <ModalBody>
                <Container>
                  {staticQr ? <QrCard token={token} user={user} postData={ `badgeType=${thisItem}` } /> : <QrDynamic closeModal={toggle} token={token} user={user} postData={ `badgeType=${thisItem}`}/>}
                </Container>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={toggle}>Esci</Button>
            </ModalFooter>
        </Modal>
    </Container>
}
