import React, {useState, useEffect} from 'react'
import {getQr} from './api'
import QRCode from 'react-qr-code';
import { Button} from 'reactstrap'
import jwtDecode from 'jwt-decode'
import moment from 'moment'
import Error from './error'

const TimeLeft = (props) => {
    const {code} = props
    const tok = jwtDecode(code)
    const exp = moment.unix(tok.exp)
    const now = moment()

    let expirated = moment(exp).add(1, "day").isSameOrBefore(now)
    const scadenza = moment.unix(tok.exp).subtract(1, "day").format('DD/MM/YYYY')
    
    return <span {...props}>
            Fino al {scadenza}
                {expirated ? " scaduto" : null}
            </span>
}


export default function QrCard (props) {
    const [data, setData] = useState({})
    const {token, user, postData} = props
    const [err, setErr] = useState(false)

    useEffect ( () => {
        getQr(token, true, postData)
            .then( ({data}) => {
                console.log(data)
                setData(data)
            }).catch(err => {
                 try{
                setData(err.response.data)
                setErr(true)
            }catch(er){
                console.log(er)
                setErr(true)
            }})
    }, [token, user, postData])

    function print() {
        let content = document.getElementById('printarea');
        let pri = document.getElementById('ifmcontentstoprint').contentWindow;
        pri.document.open();
        pri.document.write(content.innerHTML);
        pri.document.close();
        pri.focus();
        pri.print();
    }

    const styles = {
         standard : {
            container: {height: '0',width: '0', position: 'absolute'},
        img : { width: "8cm", height: "5cm", position: "absolute", top: "10px"},
        utente: { position: "absolute", left: "0.3cm", top: "1.6cm", fontSize: '9pt', fontFamily: "sans-serif"},
        code: {position: "absolute", left: "4.8cm", top: "1.44cm", },
        expiration: {position: "absolute", top: "4.85cm", left: "2.5cm", fontWeight: "bold", fontSize: '8pt', fontFamily: "sans-serif"}, 
        description: {textAlign: 'left', fontSize:"7pt", fontWeight: "bold", paddingTop: 5, display: "block", width:"4cm", color:"#222"},
        codeSize: 125,
        },
        atm2021 : {
            container: {height: '0',width: '0', position: 'absolute'},
            img : { width: "8cm", height: "5cm", position: "absolute", top: "10px"},
            utente: { position: "absolute", left: "0.3cm", top: "1.6cm", fontSize: '9pt', fontFamily: "sans-serif"},
            code: {position: "absolute", left: "4.8cm", top: "1.44cm", },
            expiration: {position: "absolute", top: "4.85cm", left: "2.5cm", fontWeight: "bold", fontSize: '8pt', fontFamily: "sans-serif"}, 
            description: {textAlign: 'left', fontSize:"7pt", fontWeight: "bold", paddingTop: 5, display: "block", width:"4cm", color:"#222"},
            codeSize: 125,}, //TODO: atm2021 oramai non si usa più ma resta per test
        atm2022 : {
            container: {height: '0',width: '0', position: "absolute"},
            img : { width: "21cm", height: "29.7cm", position: "absolute", top: "0cm"},
            expiration: {position: "absolute", top: "25cm", left: "1cm", fontWeight: "bold", fontSize: '11pt', fontFamily: "sans-serif"}, 
            utente: { position: "absolute", left: "1.0cm", lineHeight: '1.25cm', top: "25.4cm", fontSize: '10pt', fontFamily: "sans-serif",  },
            code: {position: "absolute", left: "2.1cm", top: "17.50cm", },
            codeSize: 256,
            description: {textAlign: 'left', fontSize:"10pt", fontWeight: "bold", paddingTop: 5, display: "block",  color:"#222"},
            badgeDescription: {position: "absolute", top: "25cm", left: "12cm", textAlign: 'center', fontSize:"11pt", fontWeight: "bold", paddingTop: 5, display: "block", color:"#555", fontFamily: "sans-serif", },
            },
        atm2023 : {
                container: {height: '0',width: '0', position: "absolute"},
                img : { width: "21cm", height: "29.7cm", position: "absolute", top: "0cm"},
                expiration: {position: "absolute", top: "25cm", left: "1cm", fontWeight: "bold", fontSize: '11pt', fontFamily: "sans-serif"}, 
                utente: { position: "absolute", left: "1.0cm", lineHeight: '1.25cm', top: "25.4cm", fontSize: '10pt', fontFamily: "sans-serif",  },
                code: {position: "absolute", left: "2.1cm", top: "17.50cm", },
                codeSize: 256,
                description: {textAlign: 'left', fontSize:"10pt", fontWeight: "bold", paddingTop: 5, display: "block",  color:"#222"},
                badgeDescription: {position: "absolute", top: "25cm", left: "12cm", textAlign: 'center', fontSize:"11pt", fontWeight: "bold", paddingTop: 5, display: "block", color:"#555", fontFamily: "sans-serif", },
                },
        atmunime : {
            container: {height: '0',width: '0', position: "absolute"},
            img : { width: "21cm", height: "29.7cm", position: "absolute", top: "0cm", left: "0cm"},
            expiration: {position: "absolute", top: "25cm", left: "1cm", fontWeight: "bold", fontSize: '11pt', fontFamily: "sans-serif"}, 
            utente: { position: "absolute", left: "1.0cm", lineHeight: '1.25cm', top: "25.4cm", fontSize: '10pt', fontFamily: "sans-serif",  },
            code: {position: "absolute", left: "2.1cm", top: "17.50cm", },
            codeSize: 256,
            description: {textAlign: 'left', fontSize:"10pt", fontWeight: "bold", paddingTop: 5, display: "block",  color:"#222"},
            badgeDescription: {position: "absolute", top: "27.5cm", left: "12.25cm", width: "7cm", textAlign: 'center', fontSize:"11pt", fontWeight: "bold", paddingTop: 5, display: "block", color:"#555", fontFamily: "sans-serif", },
            }
    }

    if(err) return <Error data={data} />;

    if(!data.code){
        return <i className="fa fa-spinner fa-spin"></i>
    }


    const {code, badgeType , badgeIdentifier} = data
    const decoded = jwtDecode(code) 

    let birthDate = decoded.birthDate || false

    if(birthDate){
        birthDate = moment(birthDate, 'YYYY-MM-DD').format('DD/MM/YYYY')
    }
    
    const {type} = badgeType

    const css = styles[type]
    
    return <>
            <iframe style={css.container}
            id="ifmcontentstoprint" title="daStampare" >
                <div id='printarea'>
                        <img src={`/assets/${badgeType.type}.png`} style={css.img} alt=""/>
                        <p style={css.utente}>
                            <span style={css.description}>{badgeIdentifier}</span>
                            {user && user.userData ? <span><b>{user.userData.lastName} {user.userData.firstName}</b><br /></span> : null}
                            <span><b>{birthDate}</b></span><br/>
                        </p>
                        <span style={css.badgeDescription}>{badgeType.description}</span>
                        <span style={css.code}> <QRCode value={code} size={css.codeSize} level="M" /> </span>
                        <TimeLeft code={code} style={css.expiration} />
                </div>          
            </iframe>      
            <Button color="primary" size="lg" style={{width: '100%', padding: 20}} onClick={()=>{print()}}>Stampa Badge</Button>
        </>
}