import React from 'react'
import {Route, Routes} from 'react-router-dom'
import config from '../../config'
import validUserRole, {isAdmin} from '../../utils/validUserRole'
import My from '../my'
import Dashboard from '../dashboard'
import Certificates from '../certificates'
//const {auth} = config

/***
 * In ComponentRoutes bisogna aggiungere i componenti presenti in config.
 */
const ComponentRoutes = {
  'My' : My,
  'Certificates': Certificates
}

const ProtectedRoutes = (props) => {

    return <Routes>
        {config.app.map( (item,idx) => {
            // console.log(item.hidden)
              if(! ( typeof item.hidden === "object" && item.hidden.route === true ) )
                return <Route 
                        path={item.path}
                        key={idx}
                        element={React.createElement( ComponentRoutes[item.Component], {...item.attributes, ...props} ) } 
                        />
                return null
            })}
      </Routes>
      

  }
  
  const AdminRoutes = ({token, role}) => {
  
    const {appAdmin} = config
    
    return appAdmin.length ? appAdmin.map( (item,idx) => {

      const AdminNeeded = validUserRole(item.role, role )// > -1
  // abilito il menu admin in config.default
      if(config.debug || AdminNeeded || isAdmin(role) )
     
        return <Routes>
            <Route 
                  path={item.path}
                  key={idx}
                  element={React.createElement( ComponentRoutes[item.Component], {...item.attributes, token : token} ) } 
                  />
          </Routes>
    return null  
    }) : null
    
  }
  
  const PublicRoutes = ({HomePage, isAuthenticated, user}) => <>
    {<Routes>
      <Route exact path="/" element={<HomePage 
          isAuthenticated={isAuthenticated} 
          user={user} 
          />} /> 
        <Route exact path={"/dashboard"}
        element={<Dashboard />} />
       </Routes>
    }
  </>
  export default PublicRoutes
  export {ProtectedRoutes, AdminRoutes}