import React, {useState, useEffect} from 'react'
import {getQr} from './api'
import {Col, Row,} from 'reactstrap'
import jwtDecode from 'jwt-decode'
import moment from 'moment'
import QRCode from 'react-qr-code';
import Error from './error'
import Timer from './timer'

import config from '../../config'
const {app} = config;
const {badgeLogos} = app.filter( (item) => (item.nameSpace === "my"))[0]

const TimeLeft = ({code,style}) => {

    if(!code){
        return null 
    }

    const tok = jwtDecode(code)

    const exp = moment.unix(tok.exp)
    const now = moment()
  
    let expirated = moment(exp).isSameOrBefore(now)
    const scadenza = moment.unix(tok.exp).format('DD/MM/YYYY')
    
    return <>
        <Row className="justify-content-center">
            <Col><p style={style}>
                Valido entro il: {scadenza}
            {expirated ? "Scaduto" : ""}<br />
            <Timer date={exp} />
            </p>
            </Col>
        </Row>
    </>
}
const styles = {
    container: {height: '0',width: '0', position: 'absolute'},
    description: {textAlign: 'center', fontSize:"0.8em", fontWeight: "bold", paddingTop: 10},
    code: {textAlign: 'center', fontSize:"0.8em", fontFamily: "Courier New", fontWeight: "bold", paddingTop: 0},
    expiration: {fontSize: '1.5em', fontWeight: "bold"}
}
export default function QrDynamic (props) {
    const {token, user, postData} = props
    const [data, setData] = useState({})
    const [err, setErr] = useState(false)

    useEffect ( () => {
        getQr(token, false, postData)
            .then( ({data}) => {
                setData(data)
            })
            .catch(err => {
                try{
                    setData(err.response.data)
                    setErr(true)
                }catch(er){
                    console.log(er)
                    setErr(true)
                }
                 
                
            })
    }, [token, postData])
    
    if(err){
        return <Error data={data} />
    }

    if(data && !data.code){
       // console.log(data)
        return <i className="fa fa-spinner fa-spin"></i>
    }

    

    const {badgeType, badgeIdentifier, code} = data 

    const decoded = jwtDecode(code)
    let birthDate = decoded.birthDate || false

    if(birthDate){
      
         birthDate = moment(birthDate, 'YYYY-MM-DD').format('DD/MM/YYYY')
    }

    const logo = badgeLogos[badgeType.type]

    return (<>
        <Row className="justify-content-md-center" >
            <Col className="text-center">
                <p style={styles.utente}>
                {user && user.userData ?<span><i>Utente:</i> <b>{user.userData.lastName} {user.userData.firstName}</b><br /></span> : null}
                {birthDate ? <span><i>Nata/o il:</i> <b>{birthDate}</b></span> : null}
                </p>
                {logo && logo.enabled ? <p >
                    <img src={logo.url} alt={badgeType.description} style={logo.style}/>
                </p>: null}
                <span> <QRCode value={data.code} size={300} /></span>
                <div style={styles.description}>{badgeType.description}</div>
                <div style={styles.code}>{badgeIdentifier}</div>
                <TimeLeft code={data.code} style={styles.expiration} />
            </Col>
        </Row>
    </>)
}