import React, {useEffect, useState} from 'react'
import moment from 'moment'


// eslint-disable-next-line no-extend-native
Number.prototype.pad = function(size) {
    var s = String(this);
    while (s.length < (size || 2)) {s = "0" + s;}
    return s;
  }

const calculateTimeLeft = (date) => {
    const now = moment()
    let difference = moment(date).diff(now)
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        "h": Math.floor((difference / (1000 * 60 * 60)) % 24),
        "m": Math.floor((difference / 1000 / 60) % 60),
        "s": Math.floor((difference / 1000) % 60)
    };
  }

  return timeLeft;
}


const Timer = ({date}) => {
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(date));

    useEffect(() => {
      let t = setTimeout(() => {
        setTimeLeft(calculateTimeLeft(date));
      }, 1000);
      return () => {
        clearTimeout(t); 
      };
    });
  
    const timerComponents = [];
  
    Object.keys(timeLeft).forEach((interval) => {
      /*if (!timeLeft[interval]) {
        return;
      }*/
  
      timerComponents.push(
        <span style={{color: 'green'}} key={interval.toString()}>
          {timeLeft[interval].pad(2)}<span >{interval}</span>{` `}
        </span>
      );
    });
    
    return (
      <span style={{backgroundColor: 'yellow'}}>
        {timerComponents.length ? <span style={{color: 'grey'}}>scade fra {timerComponents}</span> : <span>Scaduto!</span>}
      </span>
    );
}

export default Timer;