import React from 'react'


const Error = (err) => {
    console.log(err)
    const {data} = err
return <>
    <div>
        <h1 className="code text-danger"> <i className="fa fa-exclamation-circle"></i> Errore!</h1>
        <p>{data && data.message ? data.message : "Errore di comunicazione con il server."}</p>
    </div>

</>
}

export default Error