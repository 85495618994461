import React, { useState, useEffect} from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Container} from 'reactstrap';
import { NavBar, ErrorMessage, HomePage, Footer} from './components';
import config from './config';
import axios from 'axios'
import getToken, {saveData, clearData, getLanguage} from './utils'

import getUserDetails from './utils/userData'
import PublicRoutes, {ProtectedRoutes, AdminRoutes} from './components/routes'
import StoreContext from './components/core/'

const { auth} = config;

const defaultState = {
  isAuthenticated: false,
  user: {},
  role: [],
  error: {},
  msToken: {},
  token: {},
  configs: {}
}

function App (props) {
    const [state, setState] = useState(defaultState)
    const [error, setError] = useState({})
    const [message, setMessage] = useState([])
    const [language, setLanguage] = useState("")

  const login = React.useCallback( ()=>{
// recupero l'Access Token dalla url di connect 
    const [,msToken]= window.location.href.split('?access_token=')

    try{
      if(!msToken){
        return null
      }
      // auth.getStrapiToken è l'endpoint per recuperare i dati utente che non si trovano su strapi
      axios.get(auth.getStrapiToken + `?access_token=${msToken}` )
      .then(result => {
        const {jwt} = result.data
        console.log(jwt)
        saveData(jwt)
        getUserDetails(jwt)
        .then( res => {
            setState({ isAuthenticated : true, token: jwt, user: res.data, role: res.data.userData.roles, error: null })
        })
        .catch(err => {
         console.log("Attenzione, Utente non abilitato.\nContattare supporto tecnico.\n" + err.message.toString())
        })
      })
      .catch( err => {
        console.log(err)
      })
  } catch(err) {
      let error = {};
  
      if (typeof(err) === 'string') {
        let errParts = err.split('|');
        error = errParts.length > 1 ?
          { message: errParts[1], debug: errParts[0] } :
          { message: err };
      } else {
        error = {
          message: err.message,
          debug: JSON.stringify(err)
        };
      }
  
      setErrorMessage(error)
    }
  }, [])

  useEffect(()=>{
    
    
    async function fetchData () {
      console.log('init')

    const {data} = await getLanguage() || config.defaultLanguage
    
    setLanguage(data)
    
    try{
      const {token} = await getToken()
      .catch( error => {
          console.log(error)
          login()
      })

    const res = await getUserDetails(token)

    setState({ 
      isAuthenticated : true, 
      token: token, 
      user: res.data, 
      role: res.data.userData.roles, 
      error: null ,
    })
     
    }
    catch(err){
      console.error(err)
    }
    }
    
    fetchData()

  }, [login])

 function setErrorMessage({message, debug}) {
    setError( {message: message, debug: debug});
  }
 function logout() {
    // clear token
    clearData()
    setState(defaultState);
    
    window.location = auth.logout
  }

    const {isAuthenticated, token, user, role, configs} = state


    return (<StoreContext.Provider value={{message, setMessage, language, setLanguage, isAuthenticated, user, role, configs, token}}>
      <BrowserRouter>
        {isAuthenticated &&
        <NavBar
          authButtonMethod={isAuthenticated ? () => logout() : () => login()}
          isAuthenticated={isAuthenticated}
          user={user}
          role={role}
          token={token}
          />}
          <Container fluid={true}>
            {isAuthenticated && role ? <>
              <ProtectedRoutes 
                isAuthenticated={isAuthenticated}
                user={user}
                token={token}
                role={role}/>
              <AdminRoutes 
                isAuthenticated={isAuthenticated}
                user={user}
                token={token}
                role={role}/>
            </> : <div>
            </div>}
              <PublicRoutes HomePage={HomePage} 
                isAuthenticated={isAuthenticated}
                user={user}
                />
              <div style={{paddingBottom: 50}}> 
                &nbsp;
              </div>
            {isAuthenticated &&< Footer /> }
          </Container>
          {error ?
            <ErrorMessage message={error.message} debug={error.debug} />
          : null}
        
      </BrowserRouter>
      </StoreContext.Provider>

    );
}

export default App;