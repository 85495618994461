import React, {useState, useContext} from 'react'
import { Button, ListGroupItemHeading, Input, Modal, ModalHeader, ModalBody, ListGroup, ListGroupItem, ModalFooter, Container, Col, Row} from 'reactstrap';
import getList, {getCertificate} from './api'
import StoreContext from '../core/storeContext'
import moment from 'moment'
import PdfPaper from './pdf';
import Error from './error'

const styles ={
  blu: { color: "#0478c8"}
}


export default function Certificates () {
    const [modal, setModal] = useState(false);
    const {user, token} = useContext(StoreContext)
    const [data, setData ] = useState([])
    const [filtered, setFiltered] = useState([])
    const [thisItem, setthisItem] = useState({})
    const [err, setErr] = React.useState(false)
    const [search, setSearch] = useState("")

    const toggle = () => setModal(!modal);

    async function openBadge(id){

        getCertificate(token,id)
        .then(res => {
          setthisItem(res.data)
          setErr(false)
        })

        .catch( err => {
          setErr(err.response.data)
          setthisItem({})
        })
        .then( () => {
          setModal(true)
        })
    }

    React.useEffect ( ()=> {
      const fetchData = async  () => {
        const res = await getList(token)
        setData(res.data)
        setFiltered(res.data)
        return res
      }

      fetchData()

    }, [token])
  


    const searchTitle = (searchTerm) => {
      setSearch(searchTerm)
      if(!data.length)
        return null;

      let filteredData = []
      let st = searchTerm.trim().toLowerCase()

        if(st.length > 2){
          for (const item of data) {
                const {title,extra} = item
                const {eventSigner} = extra
  
                if (title.toLowerCase().indexOf(st) > -1 || eventSigner.toLowerCase().indexOf(st) > -1 ){
                  filteredData.push(item)
                  }            
          }
      } else {
        filteredData = data
      }
      setFiltered(filteredData)

    }

  return <Container fluid>
      {/** Ciclo di tutti i badge disponibili... */}

      <ListGroup style={{paddingTop: 20}}>
        <ListGroupItemHeading>
          <p>
            Elenco Documenti
          </p>
          <p className="small text-muted" style={{fontSize: 14}}>
            Sono elencati tutti i documenti presenti in piattaforma.
            <br/>Possono essere scaricati solo i documenti per la quale si è stati precedentemente autorizzati.
            <br />Gli altri mostreranno un avviso.
          </p>
        </ListGroupItemHeading>
        <ListGroupItem>
          <label><i className="fa fa-search"></i> Filtra per Titolo (o Responsabile dell'evento) <Input type="text" value={search} onChange={(e)=>searchTitle(e.target.value)} />
            </label>
        </ListGroupItem>
        <ListGroupItem>
          {
          filtered.length ?
            filtered.map(
              item => {
                const {eventDate, eventSigner, eventSignerDescription, header} = item.extra

                return <Row key={item.id} style={{borderBottom: "1px solid grey", paddingTop: 16}}>
                  <Col xs={8}>
                  <p>
                    <i className="fa fa-award" style={styles.blu}></i> <b style={styles.blu}>{header}</b>&nbsp;
                    {eventDate && `del ${moment(eventDate, "YYYY-MM-DD").format("DD/MM/YYYY")} `}
                      <br />
                      <b>{item.title} </b><br /> {eventSignerDescription}: <i>{eventSigner}</i>
                  </p>
                  
                  </Col>
                  <Col xs={4}>
                    <Button color="primary" outline onClick={()=>openBadge(item.id)}><i className="fa fa-print"></i> Verifica e Stampa</Button>
                  </Col>
                
                </Row> 
              }
            )
           : 
           search.length ? <p>Con il termine <b>{search}</b>, non sono stati trovati documenti.<br /> La ricerca è stata effettuata su {data.length} documenti.</p>
           : null }
        </ListGroupItem>
      </ListGroup>
      <Modal isOpen={modal} toggle={toggle} size="lg">
          <ModalHeader toggle={toggle}>Attestato </ModalHeader>
          <ModalBody>
              <Container>
                {err ? <Error data={err} /> : <>
                <p className="d-sm-block d-md-none">La piattaforma non è ottimizzata per la stampa tramite smartphone.<br />
                Per la generazione dell'attestato, e la stampa, si consiglia l'uso del Pc/Mac.</p>
                <PdfPaper item={thisItem} user={user} />
                <p className="d-sm-block d-md-none">Alcuni browser mobile non consentono la generazione ed il download automatico dell'attestato.</p>
                </>
                }
              </Container>
          </ModalBody>
          <ModalFooter>
              <Button color="secondary" onClick={toggle}>Esci</Button>
          </ModalFooter>
      </Modal>
    </Container>
}
